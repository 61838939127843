import React from 'react';
import Main from './components/Main';
function App() {
  return (
    <div className="bg-[#1e1e1e]">
    <Main/>
    </div>

  );
}

export default App;
