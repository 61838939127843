import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/free-solid-svg-icons";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [index, setIndex] = useState(0);

  const fetchTestimonials = async () => {
    try {
      const response = await fetch(
        "https://api.osarestudios.com/api/testimonials?populate=*",
        {
          headers: {
            Authorization:
              "Bearer 56b669147d515f08dd8797b1f560c3caa95a184280f5b8f5b8dc797bb6358c5047a4480a7a52e220c191bd32685008c70109e3503e3e8d09ca1fcbf7363e5872bc660f5dd35f3b6899d8c97b1faa23afbe7e782317fe33afdfd08c371f4e4b5c90948a42ad499409767e6bda0a0d11d3987ecb58c43c1aacda1642046209b7d0",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch testimonials");
      }
      const data = await response.json();
      setTestimonials(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchTestimonials();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 3500); // Change the interval time to 1000ms for 1 second
    return () => clearInterval(interval);
  }, [testimonials]);

  const handleControl = (type) => {
    if (type === "prev") {
      setIndex((prevIndex) =>
        prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
      );
    } else if (type === "next") {
      setIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }
  };

  const testimonial = testimonials[index];

  return (
    <section className="ezy__testimonial16 light pb-5 bg-[#1e1e1e] text-white">
      <h2 className="text-xl font-semibold text-center mb-6">
        <span className="text-red-600 md:text-3xl text-2xl">Testimonials</span>
      </h2>
      <div className="container px-4 mx-auto relative md:py-10">
        {testimonial && (
          <div className="grid grid-cols-2 gap-6">
            <div className="col-span-2 md:col-span-1">
              <div className="flex justify-center items-center ">
                <div className="flex items-center justify-center flex-col">
                  <div className="mr-3">
                    <img
                      src={testimonial.attributes.image.data[0].attributes.url}
                      alt={testimonial.attributes.name}
                      className="max-w-[80px] md:max-w-[100px] h-[auto] mb-4 rounded-full border border-red-600"
                    />
                  </div>
                  <div className="flex justify-center items-center flex-col">
                    <h4 className="text-xl  font-medium mb-2">
                      {testimonial.attributes.name}
                    </h4>
                    <h4 className="text-[10px] md:text-[14px] font-normal mb-2 opacity-80">
                      {testimonial.attributes.position}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1">
              <div className="flex items-center justify-center h-44 md:h-full px-10">
                <p className="relative text-md font-semibold md:text-[16px] text-center">
                  <FontAwesomeIcon
                    icon={faQuoteLeft}
                    className="absolute text-[100px] text-red-600 text-opacity-10 -top-3 lg:-top-12 left-0 transition-transform"
                  />
                  {testimonial.attributes.review}
                  <FontAwesomeIcon
                    icon={faQuoteRight}
                    className="absolute text-[100px] text-red-600 text-opacity-10 -bottom-3 lg:-bottom-12 right-0 transition-transform"
                  />
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="mt-10">
          <button
            className="absolute top-1/3 -translate-y-1/2 left-3 md:-left-3 bg-slate-800 w-12 h-12 flex justify-center items-center rounded-full"
            onClick={() => handleControl("prev")}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <button
            className="absolute top-1/3 -translate-y-1/2 right-3 md:-right-3 bg-slate-800 w-12 h-12 flex justify-center items-center rounded-full"
            onClick={() => handleControl("next")}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
