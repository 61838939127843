import React from "react";
import { Link } from "react-router-dom";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { BsInstagram, BsLinkedin, BsFacebook } from "react-icons/bs";
import { PiAndroidLogoFill } from "react-icons/pi";
import { AnimatePresence, motion } from "framer-motion";

const Home = () => {
  const [text] = useTypewriter({
    words: [
      "Light paints moments - photographer's masterpiece's immortalized",
      "Shutter clicks capturing essence - photographer's artistry's",
      "Snapshots whisper tales - photographer's eye's wonder's",
    ],
    loop: true,
    typeSpeed: 30,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });

  return (
    <div className="h-screen pt-[50%] md:pt-0 md:flex md:items-center md:justify-center">
      <div className="text-center">
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 15 }}
            transition={{ delay: 0.25 }}
          >
            <h1 className="text-lg md:text-4xl uppercase font-bold">
              Looking For A Photographer ?
            </h1>

            {/* Typewriter */}
            <div className="h-16 px-5 flex justify-center items-center">
              <p className="text-sm md:text-lg  mt-2">
                {text}{" "}
                <Cursor cursorBlinking cursorStyle="|" cursorColor="#ffaa17" />
              </p>
            </div>
            {/* Typewriter End */}

            {/* Social Icons */}
            <ul className="flex justify-center gap-4 mt-3">
              <li className="p-2 bg-white text-black hover:bg-darkRed hover:text-white rounded-full">
                <BsInstagram />
              </li>
              <li className="p-2 bg-white text-black hover:bg-darkRed hover:text-white rounded-full">
                <BsLinkedin />
              </li>
              <li className="p-2 bg-white text-black hover:bg-darkRed hover:text-white rounded-full">
                <BsFacebook />
              </li>
            </ul>
            <div className="mt-5 flex items-center justify-center">
              <button className="flex items-center justify-center w-1/2 h-12 bg-white text-black uppercase text-sm font-semibold rounded-md hover:bg-darkRed hover:text-white duration-300">
                <PiAndroidLogoFill className="mr-2 text-lg" />{" "}
                {/* Android icon */}
                Download
              </button>
            </div>
            <div className="mt-10 flex items-center flex-col justify-center">
              <p>
                Would you like to <Link to={'/hireregister'} className="text-red-600">Join</Link> our oraganization?
              </p>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
      {/* Mobile Download Button */}
    </div>
  );
};

export default Home;
