import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { HiStar } from "react-icons/hi";


const PracticeButton = ({ title, onClick, isSelected, index }) => {
  return (
    <motion.div
      className={`border border-white py-1 px-3 w-full md:w-64 flex space-x-3 items-center cursor-pointer ${
        isSelected ? "bg-white text-black" : ""
      }`}
      onClick={onClick}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <span>
        <HiStar />
      </span>
      <h1 className="text-sm">{title}</h1>
    </motion.div>
  );
};

const AreaOfPractice = () => {
  const [selectedPractice, setSelectedPractice] = useState(null);

  const [categories, setCategories] = useState("");

  useEffect(() => {
    // Fetch categories from the API
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.osarestudios.com/api/categories?populate=*",
          {
            headers: {
              Authorization: `Bearer 56b669147d515f08dd8797b1f560c3caa95a184280f5b8f5b8dc797bb6358c5047a4480a7a52e220c191bd32685008c70109e3503e3e8d09ca1fcbf7363e5872bc660f5dd35f3b6899d8c97b1faa23afbe7e782317fe33afdfd08c371f4e4b5c90948a42ad499409767e6bda0a0d11d3987ecb58c43c1aacda1642046209b7d0`,
            },
          }
        );
        // Access response.data and store it in state
        setCategories(response.data);
        setSelectedPractice(0);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, []);

  const handlePracticeClick = (index) => {
    if (index === selectedPractice) {
      // If the clicked category is already selected, return early
      return;
    }
    setSelectedPractice(index);
  };

  return (
    <div className="w-full h-screen text-white px-6 md:px-12 py-6 justify-center items-center">
      <h2 className="text-xl font-semibold text-center mt-4">
        <span className="text-red-600 text-3xl">Services</span>
      </h2>
      <div className="mt-6 flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10">
        <div className="flex-grow">
          {/* Buttons */}
          {categories?.data?.map((category, index) => (
            <PracticeButton
              key={index}
              title={
                category.attributes.name.length > 20
                  ? `${category.attributes.name.slice(0, 20)}...`
                  : category.attributes.name
              }
              onClick={() => handlePracticeClick(index)}
              isSelected={index === selectedPractice}
              index={index}
            />
          ))}
        </div>
        {/* Content */}
        <div className="flex-grow mt-4 md:mt-0">
          {selectedPractice !== null && (
            <div className="items-center justify-center w-full h-full">
              <h2 className="text-lg font-semibold ">
                {categories.data[selectedPractice]?.attributes?.name}
              </h2>
              <div className="sm:text-center md:flex mt-5 md:mt-10 justify-center items-center">
                <div className="flex items-center justify-center flex-col md:flex-row">
                  <img
                    src={
                      categories.data[selectedPractice]?.attributes.image
                        .data[0].attributes.url
                    }
                    alt="Image Description"
                    className="max-w-full h-auto my-6"
                    style={{
                      width: "180px",
                      height: "300px",
                      objectFit: "cover",
                      objectPosition: "center",
                      maxWidth: "100%",
                    }}
                  />
                  <p className="md:mx-8">
                    {
                      categories?.data[selectedPractice]?.attributes
                        ?.description
                    }
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* Other practice area contents... */}
        </div>
      </div>
    </div>
  );
};

export default AreaOfPractice;
