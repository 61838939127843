import React, { useState, useEffect } from "react";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../css/style.css";
import ReactPlayer from "react-player";

const Portfolio = () => {
  const [portfolioData, setPortfolioData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    const getPortfolio = async () => {
      try {
        setIsFetching(true);

        const authToken =
          "56b669147d515f08dd8797b1f560c3caa95a184280f5b8f5b8dc797bb6358c5047a4480a7a52e220c191bd32685008c70109e3503e3e8d09ca1fcbf7363e5872bc660f5dd35f3b6899d8c97b1faa23afbe7e782317fe33afdfd08c371f4e4b5c90948a42ad499409767e6bda0a0d11d3987ecb58c43c1aacda1642046209b7d0";

        // Set headers with auth token
        const headers = {
          Authorization: `Bearer ${authToken}`,
        };

        // Make API call for photos
        const photoResponse = await axios.get(
          "https://api.osarestudios.com/api/portfolios?populate=*",
          { headers }
        );

        const photoData = photoResponse.data.data;
        setPortfolioData(photoData);

        // Make API call for videos
        const videoResponse = await axios.get(
          "https://api.osarestudios.com/api/portfolio-videos?populate=*",
          { headers }
        );

        const videoData = videoResponse.data.data;
        setVideoData(videoData);
      } catch (error) {
        console.error("Error fetching portfolio data:", error);
      } finally {
        setIsFetching(false);
      }
    };
    getPortfolio();
  }, []);

  const openModal = (image) => {
    setSelectedImage(image);
    setModalVisible(true);
    // Prevent scrolling of main content when modal is open
    document.body.style.overflow = "hidden";
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedImage(null);
    // Re-enable scrolling of main content when modal is closed
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <div className="w-full  text-white px-6 md:px-12 py-6 justify-center items-center">
        <h2 className="text-xl font-semibold text-center mt-4">
          <span className="text-red-600 text-3xl">Portfolio</span>
        </h2>
        <div className="text-center my-5">
          <h3 className="uppercase text-sm underline underline-offset-2">
            Photos
          </h3>
        </div>
        <div className="flex justify-center items-center">
          {isFetching ? (
            <div className="h-screen">...</div>
          ) : (
            <div className="w-96">
              <Carousel
                showThumbs={true}
                showStatus={false}
                infiniteLoop={true}
                showIndicators={false}
                onClickItem={(index) => openModal(portfolioData[index])}
              >
                {portfolioData.map((item, index) => (
                  <div key={index} className="relative">
                    <img
                      src={item.attributes.media.data.attributes.url}
                      alt={item.id}
                      className="w-20 h-60 object-cover rounded-md cursor-pointer"
                    />
                  </div>
                ))}
              </Carousel>
            </div>
          )}
        </div>
      </div>
      <div className="w-full">
        <div className="text-center">
          <h3 className="uppercase text-sm my-6 underline underline-offset-2">
            Videos
          </h3>
        </div>
        <div className="flex justify-center grid md:grid-cols-3 gap-3">
          {videoData.map((video) => (
            <div key={video.id} className="max-w-full bg-[#1e1e1e] p-4 rounded">
              <ReactPlayer url={video.attributes.link} width="100%" />

              <p className="text-center font-medium pt-4">
                {video.attributes.title}
              </p>
            </div>
          ))}
        </div>
        {modalVisible && (
          <div style={modalContainerStyle}>
            <div style={modalContentStyle}>
              <button style={modalCloseButtonStyle} onClick={closeModal}>
                Close
              </button>
              <img
                src={selectedImage?.attributes.media.data.attributes.url}
                alt="Modal"
                style={modalImageStyle}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Portfolio;

// Inline styles
const modalContainerStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.75)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 9999,
};

const modalContentStyle = {
  maxWidth: "90%",
  maxHeight: "90%",
};

const modalCloseButtonStyle = {
  position: "absolute",
  top: "20px",
  right: "20px",
  color: "white",
  backgroundColor: "rgba(255, 0, 0, 0.75)",
  border: "none",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
};

const modalImageStyle = {
  maxWidth: "100%",
  maxHeight: "100%",
};
