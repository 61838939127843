import React from "react";

const Card = () => {

  return (
    <section className="ezy__testimonial16 light pb-5 bg-[#1e1e1e] text-white flex flex-col justify-center items-center">
      <h2 className="text-xl font-semibold text-center mb-6">
        <span className="text-red-600 md:text-3xl text-2xl">Contact</span>
      </h2>
      <div className="pb-4 bg-[#1e1e1e]">
        <div className="p-3 rounded-lg ">
          <div className="px-6 pb-4 ">
            <h2 className="title-font font-semibold text-center text-red-600 tracking-widest text-xs">
              ADDRESS
            </h2>
            <p className="mt-1 text-center">
            73 Shree Krishna Avenue Phase 1, Khandwa Rd, ext, Limbodi,
              Indore, Madhya Pradesh 452020
            </p>
          </div>
          <div className="px-6 mt-4 lg:mt-0">
            <h2 className="title-font text-center font-semibold text-red-600 tracking-widest text-xs">
              EMAIL
            </h2>
            <p className="text-center leading-relaxed">
              <a href="mailto:osarestudios@gmail.com">osarestudios@gmail.com</a>
            </p>

            <h2 className="title-font text-center font-semibold text-red-600 tracking-widest text-xs mt-4">
              PHONE
            </h2>
            <p className="leading-relaxed text-center" type="phone">
              <a href="tel:+918269218803">+918269218803</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Card;
