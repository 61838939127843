import React, { useState } from "react";
import Home from "./elements/Home";
import About from "./elements/About";
import Stacks from "./elements/HowItWorks";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./css/style.css";
import AreaOfPractice from "./elements/AreaOfPractice";
import { CgMenuGridO } from "react-icons/cg";
import Testimonials from "./elements/Testimonials";
import Portfolio from "./elements/Portfolio";
import RegistrationForm from "./elements/registration/RegitrationForm";
import Footer from "./elements/Footer";
import Card from "./elements/Card";

const Main = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
const location = useLocation();
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleMenuItemClick = () => {
    setMenuOpen(false); // Close the menu
  };

  return (
    <section className="bg-transparent font-[Poppins]">
      <div className="w-full min-h-screen md:min-h-0 bg-banner-bg bg-center overflow-hidden">
        <div
          className="w-full text-white"
          style={{ background: "rgba(0, 0, 0, 0.8)" }}
        >
          <div className="h-20 lg:h-18 text-black lg:text-white md:bg-transparent bg-[#1e1e1e]">
            <div className="max-w-screen-2xl h-full mx-auto px-4 flex items-center justify-between">
              <Link
                to={"/"}
                className="text-2xl uppercase font-bold text-red-600"
              >
                <h1>Osare Studios</h1>
              </Link>
              <ul className="hidden lg:inline-flex items-center gap-8 uppercase text-sm font-semibold">
                <Link to={"/"}>
                  <li className="navbarLi">Home</li>
                </Link>
                <Link to={"/services"}>
                  <li className="navbarLi">Services</li>
                </Link>
                <Link to={"/portfolio"}>
                  <li className="navbarLi">Portfolio</li>
                </Link>
                <Link to={"/about"}>
                  <li className="navbarLi">About</li>
                </Link>
              </ul>
              <div className="hidden lg:inline-flex gap-8 items-center">
                <Link to="/hireregister">
                  <button className="w-36 h-14 bg-white text-black uppercase text-sm font-semibold rounded-md hover:bg-darkRed hover:text-white duration-300">
                    Join our Team
                  </button>
                </Link>
              </div>
              <div className="inline-flex lg:hidden">
                <CgMenuGridO
                  className="text-3xl text-white"
                  onClick={toggleMenu}
                />
              </div>
            </div>
            {isMenuOpen && (
              <div className="lg:hidden fixed w-14 top-0 left-0 w-full h-full justify-center items-center flex bg-[#1e1e1e] z-50">
                <div className="max-w-screen-2xl mx-auto px-4 py-8">
                  {/* Add your mobile menu items here */}
                  <ul className="flex flex-col gap-4 text-center ">
                    <Link to="/" onClick={handleMenuItemClick}>
                      <li className="navbarLi text-xl font-medium text-red-600">
                        Home
                      </li>
                    </Link>
                    <Link to="/services" onClick={handleMenuItemClick}>
                      <li className="navbarLi text-xl font-medium text-red-600">
                        Services
                      </li>
                    </Link>
                    <Link to="/about" onClick={handleMenuItemClick}>
                      <li className="navbarLi text-xl font-medium text-red-600">
                        About
                      </li>
                    </Link>
                    <Link to="/portfolio" onClick={handleMenuItemClick}>
                      <li className="navbarLi text-xl font-medium text-red-600">
                        Portfolio
                      </li>
                    </Link>
                    <Link to="/hireregister" onClick={handleMenuItemClick}>
                      <li className="navbarLi text-xl font-medium text-red-600">
                        Join our Team
                      </li>
                    </Link>
                  </ul>
                </div>
              </div>
            )}
          </div>

          {/* Banner */}
          <div className="justify-center items-center overflow-y-auto md:pb-0">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="about" element={<About />} />
              <Route path="stacks" element={<Stacks />} />
              <Route path="services" element={<AreaOfPractice />} />
              <Route path="portfolio" element={<Portfolio />} />
              <Route path="hireregister" element={<RegistrationForm />} />
            </Routes>
          </div>
          {/* Banner End */}
        </div>
      </div>
      {location.pathname === "/" && (
        <>
          <Stacks />
          <Testimonials />
          <Card/>
          <Footer/>
        </>
      )}
    </section>
  );
};

export default Main;
