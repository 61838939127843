import React from 'react';

const About = () => {
  return (
    <div className="w-full h-screen text-white px-6 md:px-12 py-6 justify-center items-center">
      <h2 className="text-xl font-semibold text-center mt-4">
        <span className="text-red-600 text-3xl">About</span>
      </h2>
      <div>
        <p className="mt-5">
          Welcome to <b>OSARE Studios</b>, where your cinematic dreams take
          flight! Immerse yourself in the magic of on-demand videography,
          powered by the dynamic OSARIAN community. Break free from the
          ordinary! OSARE Studios is the catalyst reshaping on-demand
          videography in a world hungry for innovation. The 'Learn, Create &
          Thrive Program' awaits, offering a gateway to hands-on learning,
          income opportunities, and a community of creatives. Our mission:
          provide free videography education to 200,000-300,000 enthusiasts
          within five years. Pocket-friendly plans, starting at ₹1000-1500 per
          hour, make captivating videos accessible to all.
        </p>

        <h2 className="text-lg font-semibold mt-5 text-center">
          Exciting Features
        </h2>
        <ul className="text-center list-none">
          <li className="flex items-center">
            <span className="inline-block w-4 h-4 mr-2 bg-red-600 rounded-full"></span>
            Master the essentials of videography
          </li>
          <li className="flex items-center">
            <span className="inline-block w-4 h-4 mr-2 bg-red-600 rounded-full"></span>
            Hone your communication skills
          </li>
          <li className="flex items-center">
            <span className="inline-block w-4 h-4 mr-2 bg-red-600 rounded-full"></span>
            Dive into paid shoots
          </li>
          <li className="flex items-center">
            <span className="inline-block w-4 h-4 mr-2 bg-red-600 rounded-full"></span>
            Connect with mentors virtually
          </li>
          <li className="flex items-center">
            <span className="inline-block w-4 h-4 mr-2 bg-red-600 rounded-full"></span>
            Explore online/offline workshops
          </li>
          <li className="flex items-center">
            <span className="inline-block w-4 h-4 mr-2 bg-red-600 rounded-full"></span>
            Engage in lively meet-ups
          </li>
        </ul>

        <p className="mt-5">
          Join <b>OSARE Studios</b> – where your visual stories become
          unforgettable masterpieces!
        </p>
      </div>
    </div>
  );
};

export default About;
