import React from "react";
import { MdSearch } from "react-icons/md";
import { HiDownload } from "react-icons/hi";
import { AiOutlineFileDone } from "react-icons/ai";
export default function HowItWorks() {
  return (
    <>
      <div className="flex flex-col md:flex-row py-10 px-6 justify-around bg-[#1e1e1e]">
        <div className="flex flex-col display:flex; w-full md:h-auto md:w-1/2">
          <h5 className="text-sm text-white">Start New Project</h5>
          <h2 className="font-bold text-xl my-1 text-white">
            How it <span className="text-red-600">Works</span>?
          </h2>
          <div className="grid grid-cols-1 md:-cols-3 mr-4 gap-4 my-4 w-full md:w-auto">
            <div className="bg-[#000000] p-4 flex items-center justify-center">
              <span className="text-red-600 text-4xl">
                <HiDownload />
              </span>
              <div className="ml-2">
                <h2 className="text-white text-md">Osare Studios Apk</h2>
                <h5 className="text-white text-sm text-white mx-auto justify-center items-center">
                  Download the Osare Studios Apk from our official website.
                </h5>
              </div>
            </div>
            <div className="bg-[#000000] p-4 flex items-center justify-center">
              <span className="text-red-600 text-4xl">
                <MdSearch />
              </span>
              <div className="ml-2">
                <h2 className="text-white text-md">Book Photography Service</h2>
                <h5 className="text-white text-sm text-white mx-auto justify-center items-center">
                  Find the required photography services in our mobile
                  application.
                </h5>
              </div>
            </div>
            <div className="bg-[#000000] p-4 flex items-center justify-center">
              <span className="text-red-600 text-4xl">
                <AiOutlineFileDone />
              </span>
              <div className="ml-2">
                <h2 className="text-white text-md">Get Your Photoshoot Done</h2>
                <h5 className="text-white text-sm text-white mx-auto justify-center items-center">
                  Our photography expert comes and shoots your image as per the
                  order.
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 md:justify-center">
          <h5 className="text-sm text-white">About Our Organization</h5>
          <h2 className="font-bold text-xl my-1 text-white">
            Our <span className="text-red-600">History</span>
          </h2>
          <div className="flex flex-col md:flex-row gap-4 my-4">
            <div className="hstory">
              <img
                src="/assets/bg3.jpg"
                className="h-64 mt-4 md:mt-0 md:max-w-18"
                alt="practiceAreas[selectedPractice].title"
              />
            </div>
            <div className="md:flex md:flex-col">
              <div className="">
                <h2 className="text-md text-red-600 font-semibold">
                  Opened in 2021
                </h2>
                <h5 className="text-sm text-white">
                  Our journey began in the year 2021.
                </h5>
              </div>
              <div className="mt-2">
                <h2 className="text-md text-red-600 font-semibold">
                  Expanding Services
                </h2>
                <h5 className="text-sm text-white">
                  We've been continuously expanding our services since 2022.
                </h5>
              </div>
              <div className="mt-2">
                <h2 className="text-md text-red-600 font-semibold">
                  Building Reputation
                </h2>
                <h5 className="text-sm text-white">
                  We've built a strong reputation over the years working with
                  various industry companies and customers.
                </h5>
              </div>
              <div className="mt-2">
                <h2 className="text-md text-red-600 font-semibold">
                  Present Day
                </h2>
                <h5 className="text-sm text-white">
                  Today our firms provide the best solutions for any photoshoot
                  in this industry.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
